var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"tableMod",staticClass:"\n\t\t\ttable-modify-css table-follow-model table-scroll-y\n\t\t\theader-sticky-table header-no-border-table\n\t\t\ttable-on-max-height\n\t\t",class:_vm.tableLoading ? 'table-on-loading' : '',attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":[],"sort-desc":[false, true],"hide-default-header":false,"height":_vm.tableHeight,"multi-sort":"","hide-default-footer":"","items-per-page":10000,"loading":_vm.tableLoading,"loading-text":"Dữ liệu đang được tải xuống"},scopedSlots:_vm._u([(_vm.tableLoading)?{key:"loading",fn:function(){return [_c('span',{staticClass:"d-flex justify-center"},[_vm._v("Dữ liệu đang được tải xuống")])]},proxy:true}:null,(!_vm.tableLoading)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"d-flex justify-center"},[_vm._v("Không có dữ liệu")])]},proxy:true}:null,{key:"item.approval_counter",fn:function(ref){
var item = ref.item;
return [(item.approver_titles && item.approver_titles.length)?_c('v-tooltip',{attrs:{"top":"","content-class":'tooltip-solid'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"pointer"},'span',attrs,false),on),[_vm._v(_vm._s(item.approval_counter))])]}}],null,true)},[_c('div',_vm._l((item.approver_titles),function(approval){return _c('div',{key:approval.id,staticClass:"each-approver-block"},[_c('span',{staticClass:"status-circle",class:("status-circle__" + (approval.status))}),_vm._v(" "+_vm._s(_vm.getUserFromTitles(approval))+" ")])}),0)]):_c('span',{staticClass:"pointer"},[_vm._v(_vm._s(item.approval_counter))])]}},{key:"item.colorbox",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",class:item.status.id === 1
						? 'trash-background'
						: item.status.id === 2
						? 'sent-background'
						: item.status.id === 3
						? 'approved-background'
						: item.status.id === 4
						? 'no-approve-background'
						: item.status.id === 5
						? 'cancel-background'
						: ''})]}},{key:"item.id",fn:function(ref){
						var item = ref.item;
return [_c('router-link',{staticClass:"id-noone pointer",attrs:{"to":("/theo-doi/chi-tiet/" + (item.id))}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
						var item = ref.item;
return [_c('div',{class:item.status.id === 1
						? 'trash'
						: item.status.id === 2
						? 'sent'
						: item.status.id === 3
						? 'approved'
						: item.status.id === 4
						? 'no-approve'
						: item.status.id === 5
						? 'cancel'
						: ''},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}},{key:"item.actions",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"w-max-content"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"src":require("@/assets/images/icon-edit.svg"),"alt":""},on:{"click":function($event){return _vm.viewDetail(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Chi tiết")])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }