





























































































import {computed, defineComponent, reactive, ref, watch} from '@vue/composition-api';
import api from '@/services';
import TableFollow from '@/components/Table/TableFollow.vue';
import DateRangeTableHeader from '@/components/Shared/DateRangeTableHeader.vue';
import ConfirmRemove from '@/components/popup/ConfirmRemove.vue';
import ConfirmCancel from '@/components/popup/ConfirmCancel.vue';
import {SharedPagination} from '@/components/Shared';
import {NormalPagination} from '@/InterfaceModel/Pagination';
import {NormalHeaderItem} from '@/InterfaceModel/Header';
import {IdentifyObject} from '@/InterfaceModel/CustomObject';
import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
import {TextFilter} from '@/components/Shared';
import route from '@/router/index';
import {mapState} from 'vuex';
import {filter} from 'vue/types/umd';

export default defineComponent({
  components: {
    TableFollow,
    SharedPagination,
    ConfirmRemove,
    ConfirmCancel,
    TextFilter,
    DateRangeTableHeader,
  },
  setup: (props, ctx) => {
    const {queryRoute, stringQueryRender, getQueryRoute} = useRouteQuery();
    const selectedData = ref<Record<string, unknown>>({});
    const loadingTable = ref<boolean>(false);
    const loadingBtn = ref<boolean>(false);
    const isVisible = ref<boolean>(false);
    const isVisibleConfirm = ref<boolean>(false);
    const isVisibleDetail = ref<boolean>(false);
    const showOptionStatus = ref<boolean>(false);
    const currentRouteQuery = ref<string>(stringQueryRender);
    const messageErr = ref<string>('');
    let refInterval = ref<number>(0);
    let tableData = reactive<Record<string, any>>({value: []});
    const dataExport = ref<any>('');
    const isResCount = (listApproval: any[]) => {
      return listApproval.filter((o: any) => o.status !== 0).length;
    };
    let tableDataComputed = computed(() => {
      const arr: Record<string, any>[] = tableData.value;
      return arr.map((o: any) => {
        return {
          ...o,
          created_at: formatDate(o.created_at),
          category_name: o.category ? o.category.name : '',
          category_des: o.category ? o.category.description : '',
          approval_counter:
              o.approver_titles && Array.isArray(o.approver_titles)
                  ? `${isResCount(o.approver_titles)}/${o.approver_titles.length || 0}`
                  : '0/0',
        };
      });
    });
    let formatDate = (ds: any) => {
      let ar1 = ds.split(' ');
      let ar2 = ar1[0].split('-');
      return `${ar2[2]}/${ar2[1]}/${ar2[0]}`;
    };
    let filterTable = ref({});
    let pagination = ref<NormalPagination>({
      total: 1,
      size: 15,
      total_pages: 15,
      page: 1,
      keyword: '',
      creator: '',
      approver_status: '',
      sent_at_from: '',
      sent_at_to: '',
    });

    const headers: NormalHeaderItem[] = [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'colorbox',
        class: 'color-box',
        type: 'string',
        filters: {
          // type: "string",
          // key: "id",
          // placeholder: "No.",
          // defaultValue: "",
        },
      },
      {
        text: 'Mã số',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'noone',
        type: 'string',
        filters: {
          // type: "string",
          // key: "id",
          // placeholder: "No.",
          // defaultValue: "",
        },
      },
      {
        text: 'Trạng thái',
        align: 'start',
        sortable: false,
        value: 'status',
        class: 'address',
        type: 'string',
        filters: {
          // type: "string",
          // key: "address",
          // placeholder: "Address",
          // defaultValue: "",
        },
      },
      {
        text: 'Tiêu đề',
        align: 'start',
        sortable: false,
        value: 'name',
        class: 'company',
        type: 'string',
        filters: {
          // type: "string",
          // key: "company",
          // placeholder: "Company",
          // defaultValue: "",
        },
      },
      {
        text: 'Thông tin phê duyệt',
        align: 'start',
        sortable: false,
        value: 'approval_counter',
        class: 'approval_counter',
        type: 'string',
        filters: {},
      },
      {
        text: 'Danh mục phê duyệt ',
        align: 'start',
        sortable: false,
        value: 'category_name',
        class: 'name',
        type: 'string',
        filters: {
          // type: "string",
          // key: "name",
          // placeholder: "Contact Person",
          // defaultValue: "",
        },
      },
      {
        text: 'Người đề xuất',
        align: 'start',
        sortable: false,
        value: 'creator.full_name',
        class: 'reminiscent_name',
        type: 'string',
        filters: {
          // type: "string",
          // key: "reminiscent_name",
          // placeholder: "Reminiscent Name",
          // defaultValue: "",
        },
      },
      {
        text: 'Ngày tạo',
        value: 'created_at',
        type: 'date',
        class: 'date',
        filters: {
          type: 'daterange',
          key: 'manifest',
          placeholder: 'Date',
          defaultValue: {},
          hasKey: true,
        },
      },
      {
        text: '',
        class: 'action',
        value: 'actions',
        sortable: false,
        filters: {},
      },
    ];
    Object.freeze(headers);
    const setTableData = (payload: Record<string, unknown>[]) => {
      tableData.value = payload;
    };
    const setPagination = (payload: NormalPagination) => {
      pagination.value = {...payload};
    };
    const setCurrentRouteQuery = (payload: Record<string, unknown>): any => {
      let pairO = new IdentifyObject({
        ...payload,
      });
      currentRouteQuery.value = getQueryRoute(pairO.identifySelf());
    };
    const setShowOptionStatus = (type: boolean) => {
      showOptionStatus.value = type;
    };
    const setCurrentFilterTable = (payload: Record<string, unknown>): any => {
      filterTable.value = {...payload};
    };
    const setLoadingTable = (payload: boolean) => {
      loadingTable.value = payload;
    };
    const setLoadingBtn = (payload: boolean) => {
      loadingBtn.value = payload;
    };
    const setIsVisible = (payload: boolean) => {
      isVisible.value = payload;
    };
    const setIsVisibleDetail = (payload: boolean) => {
      isVisibleDetail.value = payload;
    };
    const setIsVisibleConfirm = (payload: boolean) => {
      isVisibleConfirm.value = payload;
    };

    const keywordComputed = computed(() => {
      return pagination.value.keyword;
    });
    const creatorComputed = computed(() => {
      return pagination.value.creator;
    });
    watch(currentRouteQuery, currentValue => {
      route.push(`${currentValue}`);
    });
    watch(pagination, currentValue => {
      const {page, size, keyword, creator, sent_at_from, sent_at_to, approver_status} = currentValue;
      setCurrentRouteQuery({
        ...queryRoute,
        page,
        size,
        keyword,
        creator,
        sent_at_from,
        sent_at_to,
        approver_status,
      });
    });
    watch(keywordComputed, keyword => {
      if (refInterval.value) {
        clearTimeout(refInterval.value);
      }

      refInterval.value = setTimeout(() => {
        setCurrentRouteQuery({
          ...queryRoute,
          keyword,
        });
      }, 600);
    });

    watch(creatorComputed, creator => {
      if (refInterval.value) {
        clearTimeout(refInterval.value);
      }

      refInterval.value = setTimeout(() => {
        setCurrentRouteQuery({
          ...queryRoute,
          creator,
        });
      }, 600);
    });
    watch(filterTable, currentValue => {
      setCurrentRouteQuery({
        ...queryRoute,
        ...currentValue,
        page: 1,
      });
    });
    watch(isVisible, currentValue => {
      if (!currentValue) {
        selectedData.value = {};
        messageErr.value = '';
      }
    });
    watch(isVisibleConfirm, currentValue => {
      if (!currentValue && !isVisible.value) {
        selectedData.value = {};
        messageErr.value = '';
      }
    });
    watch(isVisibleDetail, currentValue => {
      if (!currentValue) {
        selectedData.value = {};
        messageErr.value = '';
      }
    });

    const getAll = async (query: Record<string, unknown>) => {
      setLoadingTable(true);
      if (!Object.keys(query).length) return;
      query.include = 'fields,approvers,followers,creator,category';
      query.type = 'i_following';
      const res = await api.approval.getAll(query);
      setLoadingTable(false);
      if (!res) {
        ctx.root.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Update error',
        });
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          const _pagination = res.data.data.meta.pagination;
          if (pagination.value.page > _pagination.total_pages) {
            setPagination({
              total: _pagination.total,
              total_pages: _pagination.total_pages,
              size: _pagination.per_page,
              page: 1,
              keyword: pagination.value.keyword,
              creator: pagination.value.creator,
              sent_at_from: pagination.value.sent_at_from,
              sent_at_to: pagination.value.sent_at_to,
              approver_status: pagination.value.approver_status,
            });
          } else {
            setTableData(res.data.data.approvals);
            setPagination({
              total: _pagination.total,
              total_pages: _pagination.total_pages,
              size: _pagination.per_page,
              page: _pagination.current_page,
              keyword: pagination.value.keyword,
              creator: pagination.value.creator,
              sent_at_from: pagination.value.sent_at_from,
              sent_at_to: pagination.value.sent_at_to,
              approver_status: pagination.value.approver_status,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      headers,
      pagination,
      loadingTable,
      loadingBtn,
      tableDataComputed,
      queryRoute,
      filterTable,
      selectedData,
      isVisible,
      isVisibleDetail,
      isVisibleConfirm,
      showOptionStatus,
      messageErr,
      setTableData,
      setLoadingTable,
      setLoadingBtn,
      setCurrentRouteQuery,
      setPagination,
      setIsVisible,
      setIsVisibleConfirm,
      setIsVisibleDetail,
      getAll,
      setCurrentFilterTable,
      setShowOptionStatus,
      currentRouteQuery,
    };
  },
  computed: {
    ...mapState({
      previousPagination: (state: any) => state.previousPagination,

      listProposeStatus: (state: any) => state.listProposeStatus,
    }),
  },
  created() {
    if (this.previousPagination) {
      const body = {
        ...this.previousPagination,
      };
      this.setPagination(body);
    }
    if (this.queryRoute) {
      if (this.queryRoute.size) {
        const refPagination = {...this.pagination};
        refPagination.size = +this.queryRoute.size;
        refPagination.page = +this.queryRoute.page;
        refPagination.keyword = this.queryRoute.keyword;
        refPagination.creator = this.queryRoute.creator;
        refPagination.sent_at_from = this.queryRoute.sent_at_from;
        refPagination.sent_at_to = this.queryRoute.sent_at_to;
        refPagination.approver_status = this.queryRoute.approver_status;
        this.setPagination(refPagination);
      }
      let _obj: any = {...this.queryRoute};
      delete _obj.size, delete _obj.page;
      // this.setCurrentFilterTable(_obj)

      // this.setCurrentRouteQuery(this.queryRoute)
      this.bindingDefaultFilterHeader(_obj);
    }
    this.getAll({...this.queryRoute});
  },
  mounted() {
    this.$store.commit('SET_ADDLIB', false);
    this.$store.commit('SET_ADDLIBACTION', () => {
      console.log('nth');
    });
  },
  methods: {
    handleOpenSelectStatus() {
      this.setShowOptionStatus(true);
    },
    handleCloseStatusOnly() {
      this.setShowOptionStatus(false);
    },
    handlerDialogCancel() {
      this.setIsVisibleConfirm(true);
    },
    handlerDialogItemCancel() {
      this.setIsVisibleDetail(false);
    },
    handlerDialogConfirmCancel() {
      this.setIsVisibleConfirm(false);
    },

    getStatusName(status_id: string | number) {
      const rslt = this.listProposeStatus.filter((o: Record<string, any>) => o.id == status_id);
      return rslt && rslt.length ? rslt[0].name : 'Tất cả';
    },
    handleConfirmRemoveItem(item: Record<string, unknown>) {
      const id = this.selectedData.id;
      console.log(id);
    },
    pickedSingleDate(_val: any) {
      if (!_val || !_val.value) {
        this.setPagination({
          ...this.pagination,
          sent_at_from: '',
          sent_at_to: '',
        });
      } else {
        this.setPagination({
          ...this.pagination,
          sent_at_from: _val.value.startDate || '',
          sent_at_to: _val.value.endDate || '',
        });
      }
    },

    onChangeStatusFilter(_val: any) {
      this.setPagination({
        ...this.pagination,
        approver_status: _val.id,
      });
    },
    handleConfirmCancel() {
      this.setIsVisible(false);
      this.setIsVisibleConfirm(false);
    },
    handlerDialogSubmit(value: any) {
      if (Object.keys(this.selectedData).length) {
        const id = this.selectedData.id;
        console.log(value, id);
      } else {
        console.log(value);
      }
    },
    pagePaginationChange(_val: any) {
      this.$store.commit('CACHED_PAGINATION', {
        total: this.pagination.total,
        total_pages: this.pagination.total_pages,
        size: _val.size,
        page: _val.page,
      });
      this.setPagination({
        total: this.pagination.total,
        total_pages: this.pagination.total_pages,
        size: _val.size,
        page: _val.page,
        approver_status: this.pagination.approver_status,
        keyword: this.pagination.keyword,
        creator: this.pagination.creator,
        sent_at_to: this.pagination.sent_at_to,
        sent_at_from: this.pagination.sent_at_from,
      });
    },
    setupData() {
      this.setLoadingTable(true);
    },
    clearSetup() {
      this.setLoadingTable(true);
    },
    filterTableChange(_val: any) {
      this.setCurrentFilterTable(_val);
    },
    handlerEdit(item: Record<string, unknown>) {
      this.setIsVisible(true);
      this.selectedData = {...item};
    },
    handlerRemove(item: Record<string, unknown>) {
      this.setIsVisibleConfirm(true);
      this.selectedData = {...item};
    },
    handlerViewDetail(item: Record<string, unknown>) {
      this.setIsVisibleDetail(true);
      this.selectedData = {...item};
    },
    bindingDefaultFilterHeader(_obj: Record<string, unknown>) {
      let _headers = this.headers.slice();
      const currentQuery: Record<string, unknown> = _obj;

      for (const _key in currentQuery) {
        let _keySplit = _key.split('.');

        if (_keySplit.length === 1) {
          let n = _headers.findIndex(o => o.filters.key === _key);
          if (n !== -1) {
            //  const currentQuery = {..._headers[n]}
            //  const currentQueryF = {..._headers[n].filters}
            //  currentQueryF.defaultValue = currentQuery[_key]
            //  currentQuery.filters = {...currentQueryF}
            //  _headers.splice(n,1,currentQuery)
            if (_headers[n].filters.type === 'string') {
              _headers[n].filters.defaultValue = `${currentQuery[_key]}`;
            } else if (_headers[n].filters.type === 'select') {
              // _headers[n].filters.defaultValue = _headers[n].filters.items.find(o=>o.id === currentQuery[_key]);
              _headers[n].filters.defaultValue = parseInt(`${currentQuery[_key]}`);
            }
          }
        } else {
          const _keyNew: string = _keySplit[1];
          let n = _headers.findIndex(o => o.filters.key === _keySplit[0]);
          if (n !== -1) {
            let obj = {..._headers[n]};
            let objF = {..._headers[n].filters};
            let defaultValue = typeof objF.defaultValue === 'object' && {
              ...objF.defaultValue,
            };
            obj.filters.defaultValue = {
              ...defaultValue,
            };
            obj.filters.defaultValue[`${_keyNew}`] = currentQuery[`${_key}`];
            _headers.splice(n, 1, obj);
          }
        }
      }
    },
  },
});
