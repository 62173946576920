







import {defineComponent} from "@vue/composition-api";
import DefaultLayout from "@/layouts/default.vue";
import ContainerFollow from "@/components/containers/ContainerFollow.vue";

export default defineComponent({
  components: {
    DefaultLayout,
    ContainerFollow,
  },
  created() {
    this.$store.commit("SET_BREADCRUMB", {
      viewTxt: "Tôi theo dõi",
      ableToBack: false,
      rootRouter: "/",
      hasStatus: false,
      statusTxt: "",
    });
  },
  methods: {}
});
